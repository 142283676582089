.wallet-area {
    .wallet {
        display: flex;
        justify-content: start;

        @media only screen and (min-width: 576px) and (max-width: 719px) {
            overflow-x: scroll;
        }
        @media only screen and (min-width: 100px) and (max-width: 479px) {
            display: block;
            justify-content: start;
        }

        div {
            h5 {
                color: #c2c2c2;
            }
            div {
                text-align: center;
                background-color: #333;
                box-shadow: 0 6px 6px rgba(0, 0, 0, 0.5);
                border-top-right-radius: 25px;
                border-bottom-left-radius: 25px;
                h3 {
                    padding: 30px 20px 30px 20px;
                    color: $primary-color;
                    min-width: 210px;
                    @media only screen and (min-width: 100px) and (max-width: 479px) {
                        border-top-right-radius: 35px;
                    }
                    span {
                        color: rgba($color: $primary-color, $alpha: 0.5);
                    }
                }
            }
        }
        .left {
            margin-right: 20px;
            @media only screen and (min-width: 100px) and (max-width: 479px) {
                margin-right: 0px;
            }
        }
        .right {
            margin-left: 20px;
            @media only screen and (min-width: 100px) and (max-width: 479px) {
                margin-left: 0px;
            }
        }
    }
    .descr {
        margin-top: 10px;
        h6 {
            color: #c2c2c2;
            margin-top: 20px;
        }
        p {
            color: #626262;
        }
        div {
            p {
                color: #c2c2c2;
                span {
                    color: #fff;
                    font-weight: 400;
                }
            }
        }
    }
}
