.order-success {
    .section-top {
        .container {
            .row {
                display: flex;
                justify-content: center;
                div {
                    text-align: center;
                    i {
                        font-size: 70px;
                        color: #89fa95;
                    }
                    h2 {
                        color: #fff;
                    }
                    div {
                        color: #89fa95;
                        font-size: 15px;
                    }
                    p {
                        color: #fff;
                        font-size: 20px;
                        span {
                            color: #fff;
                            font-weight: 500;
                        }
                    }
                    hr {
                        width: 70%;
                        border-top: 1px solid gray;
                    }
                }
            }
        }
    }

    .section-middle {
        .container {
            .row {
                display: flex;
                justify-content: center;
                div {
                    text-align: center;
                    h4 {
                        @media screen and (max-width: 864px) {
                            display: none;
                        }
                        color: #fff;
                    }
                    div {
                        text-align: start;
                        display: flex;
                        justify-content: center;
                        @media screen and (max-width: 864px) {
                            display: block;
                            justify-content: center;
                        }
                        padding: 10px 10px 10px 10px;
                        div {
                            display: block;
                            width: 45%;
                            @media screen and (max-width: 864px) {
                                width: 100%;
                                text-align: center;
                                border-bottom: 1px solid #626262;
                            }
                            h5 {
                                color: #e3e3e3;
                            }
                            ul {
                                @media screen and (max-width: 864px) {
                                    text-align: start;
                                }
                                li {
                                    color: #c2c2c2;
                                    span {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                        .left {
                            text-align: start;
                            border-right: 1px solid #626262;
                            @media screen and (max-width: 864px) {
                                text-align: center;
                                border-right: none;
                                // border-bottom: 1px solid #626262;
                            }
                        }
                        .right {
                            text-align: end;
                            @media screen and (max-width: 864px) {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }

    .middle-bottom {
        .container {
            .row {
                display: flex;
                justify-content: center;
                div {
                    text-align: center;
                    h5 {
                        color: #fff;
                        span {
                            color: $primary-color;
                        }
                    }
                    div {
                        text-align: start;
                        display: flex;
                        justify-content: center;
                        padding: 10px 10px 10px 10px;
                        div {
                            display: block;
                            width: 90%;
                            h5 {
                                color: #e3e3e3;
                            }
                            ul {
                                li {
                                    p {
                                        color: #c2c2c2;
                                        span {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .section-bottom {
        .container {
            .row {
                display: flex;
                justify-content: center;
                div {
                    text-align: center;
                    hr {
                        width: 90%;
                        border-top: 1px solid gray;
                        margin: 1rem auto 2.5rem;
                    }
                    h4 {
                        color: #fff;
                    }
                    div {
                        display: flex;
                        justify-content: center;
                        table {
                            width: 90%;
                            thead {
                                border-bottom: 1px solid gray;
                                tr {
                                    th {
                                        color: #e3e3e3;
                                        padding-bottom: 5px;
                                        padding-top: 20px;
                                    }
                                    .first {
                                        text-align: start;
                                        padding-left: 10px;
                                        width: 50%;
                                        @media screen and (max-width: 720px) {
                                            width: 60%;
                                        }
                                    }
                                    .order-items-mobile {
                                        @media screen and (max-width: 720px) {
                                            display: none;
                                        }
                                    }
                                    .order-items-mobile-show {
                                        @media screen and (min-width: 721px) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    border-bottom: 1px solid #212121;
                                    td {
                                        padding-top: 13px;
                                        padding-bottom: 13px;
                                        color: #c2c2c2;
                                    }
                                    .first {
                                        text-align: start;
                                        padding-left: 10px;
                                    }
                                    .order-items-mobile {
                                        @media screen and (max-width: 720px) {
                                            display: none;
                                        }
                                    }
                                    .order-items-mobile-show {
                                        text-align: start;
                                        span {
                                            color: #fff;
                                        }
                                        @media screen and (min-width: 721px) {
                                            display: none;
                                        }
                                    }
                                }
                                tr:last-child {
                                    border-bottom: 1px solid gray;
                                }
                            }
                            tfoot {
                                tr {
                                    th {
                                        text-align: end;
                                        padding-top: 10px;
                                        padding-bottom: 10px;
                                        p {
                                            color: #c2c2c2;
                                            padding-right: 10px;
                                            padding-top: 6px;
                                            padding-bottom: 6px;
                                            border-bottom: 1px solid #212121;
                                            span {
                                                color: #fff;
                                            }
                                        }
                                        .last {
                                            border-bottom: none;
                                            span {
                                                font-size: 20px;
                                                font-weight: 600;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .section-btn {
        .container {
            .row {
                display: flex;
                justify-content: center;
                div {
                    text-align: center;
                    hr {
                        width: 75%;
                        border-top: 1px solid gray;
                        margin: 1rem auto 1rem;
                    }
                    .btn-outline-primary {
                        &:hover {
                            background-color: $primary-color;
                            color: #fff;
                            border-color: $primary-color;
                        }
                    }
                }
            }
        }
    }

    .section-tracking {
        .container {
            .row {
                display: flex;
                justify-content: center;
                div {
                    text-align: center;
                    hr {
                        width: 90%;
                        border-top: 1px solid gray;
                        margin: 1rem auto 2.5rem;
                    }
                    div {
                        color: #fff;
                        ul {
                            li {
                                color: #c2c2c2;
                                span {
                                    color: #fff;
                                }
                            }
                        }
                    }
                    .btn-outline-primary {
                        &:hover {
                            background-color: $primary-color;
                            color: #fff;
                            border-color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}
