.gallery-card-container {
    article {
        max-height: inherit;
        padding: 0;
        margin-bottom: 24px;
        background-color: #3e3e3e;
        border-radius: 3px;

        .gallery-card-header {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .user-data {
                display: flex;
                justify-content: flex-start;

                .user-profile-pic {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    overflow: hidden;
                }

                .user-details {
                    margin-left: 20px;

                    .header-name {
                        color: #fff;
                        font-weight: 500;
                        line-height: normal;
                    }

                    .header-city {
                        color: #898989;
                        font-size: small;
                        line-height: normal;
                    }
                }
            }

            .uploaded-at {
                font-size: small;

                div {
                    color: #fff;
                    transition: 0.3s;
                    cursor: pointer;

                    &:hover {
                        color: #c96;
                    }

                    svg {
                        font-size: larger;
                    }
                }
            }
        }

        .gallery-card-image {
            text-align: -webkit-center;
            background-color: #3e3e3e;
            // aspect-ratio: 1 / 1;
            overflow: hidden;

            // img {
            //     height: 100%;
            //     width: 100%;
            //     object-fit: cover;
            // }
        }

        .gallery-card-options {
            .shop-link {
                padding: 10px;
                border-bottom: 1px solid #4b4b4b;

                a {
                    font-size: small;
                    font-weight: 500;
                }
            }

            .bottom-options {
                display: flex;
                justify-content: space-between;
                padding: 10px;

                .left-part {
                    display: flex;
                    justify-content: flex-start;
                    .like-btn {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        cursor: pointer;

                        i {
                            font-size: x-large;
                            color: #fff;
                        }

                        span {
                            font-size: small;
                            color: #898989;
                            padding-left: 5px;
                        }
                    }
                }

                .total-likes {
                    font-size: small;
                    align-self: center;
                    color: #e6e6e6;

                    span {
                        font-weight: 600;
                        color: #fff;
                    }
                }
            }
        }

        .gallery-card-content {
            padding: 0px 10px 10px 10px;

            span {
                padding-left: 10px;
                color: #fff;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }
}

.mood-container {
    display: flex;
    justify-content: space-evenly;

    div {
        cursor: pointer;
        transition: 0.3s;
        border-radius: 5px;

        &:hover {
            background-color: #fff;
        }
    }
}

.mood-bg {
    background-color: rgb(134, 237, 61);
}
