@import "./my-account-custom.scss";
@import "./wallet-txn.scss";
@import "./gallery-page.scss";

//spinner
.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid #fff;
    border-left-color: $primary-color;
    border-radius: 974px;
    -o-border-radius: 974px;
    -ms-border-radius: 974px;
    -webkit-border-radius: 974px;
    -moz-border-radius: 974px;
}

.cssload-whirlpool {
    margin: -24px 0 0 -24px;
    height: 49px;
    width: 49px;
    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::before {
    content: "";
    margin: -22px 0 0 -22px;
    height: 43px;
    width: 43px;
    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::after {
    content: "";
    margin: -28px 0 0 -28px;
    height: 55px;
    width: 55px;
    animation: cssload-rotate 2300ms linear infinite;
    -o-animation: cssload-rotate 2300ms linear infinite;
    -ms-animation: cssload-rotate 2300ms linear infinite;
    -webkit-animation: cssload-rotate 2300ms linear infinite;
    -moz-animation: cssload-rotate 2300ms linear infinite;
}

@keyframes cssload-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes cssload-rotate {
    100% {
        -o-transform: rotate(360deg);
    }
}

@-ms-keyframes cssload-rotate {
    100% {
        -ms-transform: rotate(360deg);
    }
}

@-webkit-keyframes cssload-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes cssload-rotate {
    100% {
        -moz-transform: rotate(360deg);
    }
}

//overlay
.overlay-spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #212121;
    opacity: 0.5;
    display: none;
}

///customs
.btn-custom {
    color: $primary-color;
    border-color: $primary-color;
    background-color: transparent;
}

.btn-custom:hover {
    color: #fff !important;
    background-color: $primary-color;
}

.icon-custom-hover:hover i {
    color: #cd9967;
    transition: 0.3s;
}

.all-bg {
    background-color: #212121;
}

.all-bg-light {
    background-color: #333333;
}

.product.product-7 .btn-product {
    background-color: #333333;
}

.all-font {
    color: #c2c2c2;
}

.all-font-dark {
    color: #9c9c9c;
}

.all-font-hover {
    color: #fff;
}

.all-font-hover:hover {
    color: $primary-color;
    transition: 0.3s;
}

.font-primary {
    color: $primary-color;
}

.product-details-quantity .form-control {
    border-color: #424242;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.details-filter-row .form-control {
    border-color: #424242;
}

.details-filter-row .select-custom::after {
    color: #c2c2c2;
}

.input-spinner .form-control {
    border-color: #424242;
    background-color: transparent;
}

.all-font-light {
    color: #fff;
}

.active-new {
    color: $primary-color;
}

.menu > li > a {
    color: #fff;

    a:hover {
        color: $primary-color;
    }
}

.sticky-bar {
    box-shadow: 0 0 5px #616161;
}

.product-details-tab .nav.nav-pills .nav-link {
    color: #c2c2c2;
}

.btn-product + .btn-product {
    border: none;
}

.about-img-front {
    border: 2rem solid #424242;
}

.form-control {
    background-color: transparent;
    border-color: #424242;
}

.btn-layout.active svg {
    fill: $primary-color;
}

.product.product-11 .btn-product-icon {
    color: $primary-color;
}

.toolbox-info span {
    color: $primary-color;
}

.widget-clean label {
    color: #c2c2c2;
}

.widget-collapsible .widget-title a {
    color: #c2c2c2;
}

.widget-collapsible .widget-title a:after {
    color: #c2c2c2;
}

.price-slider .input-range__track--active {
    background-color: #c2c2c2;
}

.price-slider .input-range__track--background {
    background-color: #616161;
}

.price-slider .input-range__label-container {
    color: $primary-color;
}

.page-header {
    background-color: #424242;
    border: 20px solid #212121;
}

.product-col .product-title a {
    color: #fff;
}

.table .price-col {
    color: $primary-color;
}

.table.table-summary .summary-subtotal td {
    color: #c2c2c2;
}

.table.table-summary .summary-shipping td {
    color: #fff;
}

.table.table-summary .summary-shipping-row label {
    color: #c2c2c2;
}

.summary-cart .table.table-summary thead th:last-child,
.summary-cart .table.table-summary tbody td:last-child {
    color: #c2c2c2;
}

.summary {
    border: 0.1rem dashed #616161;
}

.header-menu ul span {
    padding: 0.3rem 0.8rem;
    color: #6b6b6b;
    transition: 0.3s;

    &:hover {
        color: $primary-color;
    }
}

.showpass-btn-effect {
    font-size: larger;
}

.input-group-text {
    color: #616161;
    background-color: transparent;
    border-color: #424242;
    font-size: 20px;
    cursor: pointer;
}

.input-group-text:hover {
    color: $primary-color;
    transition: 0.3s;
}

.capswarn {
    color: #ed5045;
}

.nav-dashboard .nav-link {
    color: #c2c2c2;
    border-bottom: 0.1rem solid #ebebeb;
}

.alert-danger {
    color: rgba($color: red, $alpha: 1.2);
    background-color: rgba($color: red, $alpha: 0.2);
}

.alert-warning {
    color: rgba($color: orange, $alpha: 1.2);
    background-color: rgba($color: orange, $alpha: 0.2);
}

.alert-success {
    color: rgba($color: green, $alpha: 1.2);
    background-color: rgba($color: green, $alpha: 0.2);
}

.alert {
    display: none;
}

.dashboard .form-control:not(:focus) {
    background-color: #212121;
}

.dashboard .form-control {
    margin-bottom: 0rem;
}

.checkout .form-control {
    margin-bottom: 0rem;
}

.checkout .form-control:not(:focus) {
    background-color: #212121;
}

.form-control:focus {
    color: #9c9c9c;
    background-color: #212121;
    border-color: #c96;
    box-shadow: none;
    outline: none !important;
}

.btn.btn-spinner {
    color: #c2c2c2;
}

.table.table-cart .quantity-col {
    padding-left: 18px;
}

.table.table-summary thead th {
    color: #c2c2c2;
}

.accordion-summary .card-title {
    color: #c2c2c2;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        color: $primary-color;
    }
}

.own-ref {
    display: flex;
    justify-content: start;
    div {
        text-align: center;
        padding: 20px 20px 20px 20px;
        h3 {
            color: #c2c2c2;
        }
        h1 {
            color: #eb3434;
            font-weight: 700;
            // border: dashed 1px #424242;
            padding: 15px 10px 15px 10px;
            background-color: #f0f0f0;
            border-bottom-right-radius: 40px;
            border-top-left-radius: 40px;
        }
        h6 {
            color: #626262;
        }
    }
    button {
        color: #9c9c9c;
        margin-left: 40px;
        transition: 0.3s;
        transform: scale(1.5);

        &:hover {
            color: $primary-color;
        }

        // i {
        //     font-size: xx-large;

        // }
    }
}

.toolbox select.form-control:not([size]):not([multiple]) {
    max-width: 2000px;
    padding-right: 35px;
}

.toolbox .select-custom::after {
    color: #c2c2c2;
}

.card.card-sm .card-title .toggle-button:before {
    cursor: pointer;
}

.card-title .toggle-button.collapsed {
    color: #c2c2c2;
    cursor: pointer;
}

.card.card-sm .card-title .toggle-button {
    cursor: pointer;
}

.card.card-sm .card-body {
    padding: 1rem 2rem 2rem;
    color: #9c9c9c;
}

.card {
    border-top: 1px solid #424242;
}

.card:last-child {
    border-bottom: 1px solid #424242;
}

.custom-hr {
    width: 70%;
    border-color: #fff;
}

.custom-btn-primary-2 {
    color: $primary-color;
    border-color: $primary-color;
}

.overlay-404 {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    width: 100vw;
    height: 100%;
    opacity: 1;
}

.reecraft-footer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    hr {
        border-color: #6b6b6b;
        max-width: 100px;
    }
    p {
        a {
            font-size: 17px;
            font-weight: 700;
            color: #a17850;
        }
    }
}

.color-display {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: transform 0.25s;
    &:hover {
        z-index: 1;
        transform: scale(1.8);
    }
}

.testimonial img {
    max-width: 175px;
}

.reset-btn {
    cursor: pointer;
    transition: 0.15s;
    &:hover {
        color: $primary-color;
    }
}

//custom check
.custom-control.custom-radio2 {
    .custom-control-label {
        color: #c2c2c2;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            color: $primary-color;
        }
    }
    .custom-control-label::before {
        top: 0.4rem;
        background-color: #333333;
        border-color: #dadada;
        border-radius: 50%;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: $primary-color;
        background-color: #333333;
    }

    .custom-control-label::after {
        border-radius: 50%;
        width: 0.6rem;
        height: 0.6rem;
        top: 0.9rem;
        left: 0.5rem;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background-image: none !important;
        z-index: 2;
        background-color: $primary-color;
    }
}

.checkout .custom-control {
    margin-top: 0rem;
}
.checkout .custom-control + .custom-control {
    margin-top: 0rem;
}

.custom-control {
    margin-top: 1rem;
    margin-bottom: 0rem;
}

.sub1 {
    display: none;
    transition: 3s;
}

:checked ~ .sub1 {
    display: block;
    margin-left: 25px;
    transition: 5s;
}

.hide-this {
    display: none;
}

.checkout-customs {
    .wallet {
        margin-top: 10px;
    }
}

//custom check tick
.custom-control.custom-checkbox2 {
    .custom-control-label {
        color: #c2c2c2;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            color: $primary-color;
        }
    }
    .custom-control-label::before {
        top: 0.4rem;
        background-color: #333333;
        border-color: #dadada;
        border-radius: 50%;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: $primary-color;
        background-color: #333333;
    }

    .custom-control-label::after {
        border-radius: 50%;
        width: 0.6rem;
        height: 0.6rem;
        top: 0.9rem;
        left: 0.5rem;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background-image: none !important;
        z-index: 2;
        background-color: $primary-color;
    }
}

.joinus-form {
    display: flex;
    justify-content: center;
    padding: 50px 0px 50px 0px;
    div {
        form {
            padding: 50px 50px 50px 50px;
        }
    }
}

.account-details {
    display: flex;
    justify-content: center;
    ul {
        li {
            color: #c2c2c2;
            span {
                color: #c96;
                font-weight: 400;
            }
        }
    }
}

.header-2 .mobile-menu-toggler {
    margin-right: 10px;
}

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$lg2-layout: "only screen and (min-width: 1107px) and (max-width: 1198px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm2-layout: "only screen and (min-width: 576px) and (max-width: 866px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";
$xxxs-layout: "only screen and (min-width: 100px) and (max-width: 319px)";
$cust1-layout: "only screen and (min-width: 100px) and (max-width: 991px)";
$xxs2-layout: "only screen and (min-width: 100px) and (max-width: 575px)";
$cust2-layout: "only screen and (min-width: 455px) and (max-width: 486px)";
$cust3-layout: "only screen and (min-width: 100px) and (max-width: 454px)";
$cust4-layout: "only screen and (min-width: 960px) and (max-width: 1300px)";
$cust5-layout: "only screen and (min-width: 992px)";
$cust6-layout: "only screen and (min-width: 591px) and (max-width: 991px)";
$cust7-layout: "only screen and (min-width: 992px) and (max-width: 1106px)";
$cust8-layout: "only screen and (max-width: 575px)";
$cust9-layout: "only screen and (min-width: 445px) and (max-width: 509px)";

// html,
// body {
//     @media #{$xxs-layout} {
//         width: 100%;
//         // height: 100%;
//         // margin: 0px;
//         // padding: 0px;
//         overflow-x: hidden;
//     }
// }

.header-custom-mobile {
    background-color: #333333;
    top: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    justify-content: space-around;
    div {
        width: 50%;
    }
    .left {
        padding-left: 15px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .right {
        display: flex;
        justify-content: flex-end;
        padding-right: 5px;
    }
    a {
        margin: 0;
    }
    @media only screen and (min-width: 992px) {
        display: none;
    }
}

.header-custom-mobile-fixed {
    position: fixed;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
}

.banner > a {
    @media only screen and (max-width: 992px) {
        height: auto !important;
    }
}

.small-hide {
    @media only screen and (max-width: 767px) {
        display: none;
    }
}

.big-hide {
    @media only screen and (min-width: 768px) {
        display: none;
    }
}

.banner-title {
    @media only screen and (min-width: 768px) and (max-width: 992px) {
        font-size: 2rem;
    }
    @media only screen and (max-width: 529px) {
        font-size: 1.8rem;
    }
}

.mobile-none {
    @media #{$cust1-layout} {
        display: none;
    }
}

.mobile-none-color {
    display: flex;
    justify-content: center;
    @media #{$cust1-layout} {
        display: none;
    }
}

.desktop-hide {
    @media #{$cust5-layout} {
        display: none;
    }
}

.desktop-hide-bnb {
    @media #{$cust5-layout} {
        display: none !important;
    }
}

.ds-certificate {
    display: flex;
    justify-content: center;
    div {
        width: 85%;
        position: relative;
        text-align: center;
        img {
            width: 100%;
        }
        h5 {
            @media #{$cust7-layout} {
                font-size: 18px;
            }
            @media #{$cust1-layout} {
                font-size: 15px;
            }
            @media #{$sm-layout} {
                font-size: 12px;
            }
            @media #{$cust8-layout} {
                font-size: 2.5vw;
            }

            span {
                color: #4f4f4f;
                font-weight: 500;
            }
            width: 90%;
            font-weight: 650;
            position: absolute;
            top: 51%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #111183;
        }

        h2 {
            @media #{$lg-layout} {
                font-size: 37px;
            }
            @media #{$lg2-layout} {
                font-size: 35.5px;
            }
            @media #{$cust7-layout} {
                font-size: 33.5px;
            }
            @media #{$md-layout} {
                font-size: 29px;
            }
            @media #{$sm2-layout} {
                font-size: 25px;
            }
            @media #{$cust8-layout} {
                font-size: 5vw;
            }
            font-weight: 700;
            position: absolute;
            top: 81.1%;
            left: 49%;
            transform: translate(-50%, -50%);
            color: #111183;
        }
        h6 {
            @media #{$cust7-layout} {
                font-size: 14px;
            }
            @media #{$cust1-layout} {
                font-size: 11.5px;
            }
            @media #{$sm-layout} {
                font-size: 9.5px;
            }
            @media #{$cust8-layout} {
                font-size: 2vw;
            }
            font-weight: 650;
            position: absolute;
            top: 80%;
            left: 23%;
            transform: translate(-50%, -50%);
            color: #111183;
        }
    }
}

.recharge-plans-desktop {
    display: flex;
    justify-content: center;
    div {
        // width: 30%;
        margin-left: 1.7rem;
        margin-right: 1.7rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
            // transform: scale(1.005);
            box-shadow: 0 0 25px 5px #333333;
        }
        p {
            color: #c2c2c2;
            .icon-check {
                color: greenyellow;
            }
            .icon-close {
                color: red;
            }
        }
    }
}

.recharge-plans-mobile {
    background-color: #333333;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
    h3 {
        color: $primary-color;
    }
    h4 {
        color: #c2c2c2;
    }
    p {
        color: #c2c2c2;
        .icon-check {
            color: greenyellow;
        }
        .icon-close {
            color: red;
        }
    }
}

.cust-btn {
    &:hover {
        background-color: $primary-color;
        color: #fff;
        border-color: $primary-color;
    }
}

.about-img-back {
    @media #{$cust1-layout} {
        display: none;
    }
}

.product-action {
    @media #{$cust1-layout} {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        // background-color: rgba(255, 255, 255, 0.95);
        z-index: 10;
        transition: all 0.35s ease;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}

.product-action-vertical {
    @media #{$cust1-layout} {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 2rem;
        top: 2rem;
        // background-color: transparent;
        z-index: 10;
        visibility: visible;
        opacity: 1;
        transition: all 0.35s ease;
        transform: translateX(0);
    }
}

.btn-cart:before {
    @media #{$cust1-layout} {
        content: "";
    }
}

.header-right {
    .wishlist {
        @media #{$cust1-layout} {
            display: none;
        }
    }
    .account {
        display: flex;
        @media #{$cust1-layout} {
            display: none;
        }
    }

    .cart-dropdown {
        @media #{$cust1-layout} {
            display: none;
        }
    }
}

.header-2 .header-top {
    background-color: #333333;
    @media #{$cust1-layout} {
        display: none;
    }
}

.header-2 .logo {
    @media #{$cust1-layout} {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

.mobile-special {
    span {
        font-weight: 700;
        color: #e0e2ff;
        span {
            font-weight: 750;
            color: #c4c8ff;
        }
    }
}

.mmenu-active .page-wrapper {
    transform: translateX(0px);
}

.return-exchange-table {
    thead {
        tr {
            th {
                color: #c2c2c2;
                width: 100px;
                border-bottom: 1px solid #626262;
                text-align: center;
            }
        }
    }
    tbody {
        tr {
            td {
                span {
                    color: #fff;
                }
                border-bottom: 1px solid #626262;
            }
        }
    }
}

.sidebar-fixed-toggler {
    display: block;
    position: fixed;
    left: 0;
    top: 110px;
    width: 55px;
    height: 65px;
    z-index: 10002;
    color: #fff;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: rgba(0, 0, 0, 0.87);
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition: transform 0.35s;

    .icon-cog:before {
        content: "\e80d";
        font-size: 30px;
    }
}

.sff-hide {
    @media only screen and (max-width: 755px) {
        display: none;
    }
}

.product.product-11 .product-action {
    bottom: 1rem;
}

.product.product-11 .product-body {
    padding-bottom: 5rem;
}

.product-media::before {
    padding-top: 121%;
}

.sticky-footer-nav-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    overflow: hidden;
    background-color: #333333;
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 -10px 6px -6px black;

    .sticky-footer-nav-bar-option {
        text-align: center;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            color: #fff;
        }

        div {
            font-size: 11px;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.opt-color {
    color: #fff !important;
}
