.order-list {
    div {
        display: flex;
        background-color: #333333;
        div {
            display: inline;
            width: 100%;
            padding: 2rem;
            border-bottom: 3px solid #212121;
            h6 {
                color: #c2c2c2;
                margin-bottom: 1rem;
                margin-top: 1rem;
                span {
                    color: #fff;
                }
            }
        }
        .left {
            text-align: start;
            h6 {
                @media only screen and (min-width: 848px) and (max-width: 894px) {
                    font-size: 15px;
                }
                @media only screen and (min-width: 100px) and (max-width: 847px) {
                    font-size: 13.5px;
                }
            }
            .small-sizes {
                display: none;
                @media only screen and (min-width: 576px) and (max-width: 706px) {
                    display: block;
                }
                @media only screen and (max-width: 471px) {
                    display: block;
                }
            }
        }
        .other-none {
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .other-yes {
            display: none;
            @media only screen and (max-width: 991px) {
                display: block;
            }
        }
        .right {
            @media only screen and (min-width: 576px) and (max-width: 706px) {
                display: none;
            }
            @media only screen and (max-width: 471px) {
                display: none;
            }
            text-align: center;
            // overflow: hidden;
            .a-dummy {
                cursor: pointer;
                &:hover {
                    color: #c96;
                }
            }
            .btn-outline-white {
                color: #9c9c9c;
                border-color: #9c9c9c;
                &:hover {
                    color: #fff;
                    border-color: $primary-color;
                }
            }

            .container {
                border-top: 1.5px solid #c2c2c2;
                display: flex;
                list-style: none;
                padding: 0;
                justify-content: space-between;
                align-items: stretch;
                align-content: stretch;
                width: 90%;
            }

            h5 {
                color: #fff;
                padding: 0;
            }

            .link {
                position: relative;
                margin-top: 10px;
                width: 100%;
            }

            .link a {
                font-weight: bold;
                text-decoration: none;
                color: black;
                text-transform: uppercase;
                font-size: 6px;
            }

            .link:first-child {
                margin-left: -55px;
            }

            .link:last-child {
                margin-right: -55px;
            }

            .link::after {
                content: "";
                width: 15px;
                height: 15px;
                background: #fff;
                position: absolute;
                border-radius: 15px;
                top: -18.5px;
                left: 50%;
                transform: translatex(-50%);
                border: 0.5px solid gray;
                cursor: pointer;
            }

            .active::after {
                background: #3ed652;
                width: 22px;
                height: 22px;
                border-radius: 22px;
                top: -22px;
            }

            .active-done::after {
                background: #8bfc9a;
                width: 17.5px;
                height: 17.5px;
                border-radius: 17.5px;
                top: -20px;
            }
        }
    }
    .empty-list {
        display: inline;
        background-color: none;

        .btn-outline-primary-2 {
            background-color: #212121;
            &:hover {
                background-color: $primary-color;
            }
        }
    }
}

$numDots: 20;
$parentWidthBase: 0.8;
$parentMaxWidth: 2000px;
$dotWidth: 20px;
$dotWidthSm: 17px;
$active: $primary-color;
$inactive: #aeb6bf;

.flex-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.input-flex-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    height: 100px;
    max-width: $parentMaxWidth;
    position: relative;
    z-index: 0;
}
.input {
    width: $dotWidth;
    height: $dotWidth;
    background-color: $active;
    position: relative;
    border-radius: 50%;
    &:hover {
        cursor: pointer;
    }
    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        background-color: $active;
        @media only screen and (min-width: 1182px) {
            width: 1570px / $numDots;
        }
        @media only screen and (min-width: 992px) and (max-width: 1181px) {
            width: 132vw / $numDots;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 150vw / $numDots;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 170vw / $numDots;
        }
        @media only screen and (min-width: 100px) and (max-width: 575px) {
            width: 180vw / $numDots;
        }
        height: 5px;
        max-width: $parentMaxWidth / $numDots;
    }
    &::before {
        @media only screen and (min-width: 1182px) {
            left: calc(#{-1570px / $numDots} + #{$dotWidth / 2});
        }
        @media only screen and (min-width: 992px) and (max-width: 1181px) {
            left: calc(#{-132vw / $numDots} + #{$dotWidth / 2});
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
            left: calc(#{-150vw / $numDots} + #{$dotWidth / 2});
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
            left: calc(#{-170vw / $numDots} + #{$dotWidth / 2});
        }
        @media only screen and (min-width: 100px) and (max-width: 575px) {
            left: calc(#{-180vw / $numDots} + #{$dotWidth / 2});
        }
    }
    &::after {
        @media only screen and (min-width: 1182px) {
            right: calc(#{-1570px / $numDots} + #{$dotWidth / 2});
        }
        @media only screen and (min-width: 992px) and (max-width: 1181px) {
            right: calc(#{-132vw / $numDots} + #{$dotWidth / 2});
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
            right: calc(#{-150vw / $numDots} + #{$dotWidth / 2});
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
            right: calc(#{-170vw / $numDots} + #{$dotWidth / 2});
        }
        @media only screen and (min-width: 100px) and (max-width: 575px) {
            right: calc(#{-180vw / $numDots} + #{$dotWidth / 2});
        }
    }
    &.active {
        background-color: $active;
        &::before {
            background-color: $active;
        }
        &::after {
            background-color: $active;
        }
        span {
            font-weight: 700;
            color: $primary-color;
            &::before {
                font-size: 13px;
            }
            &::after {
                font-size: 21px;
            }
        }
    }
    &.active ~ .input {
        &,
        &::before,
        &::after {
            background-color: $inactive;
        }
    }
    span {
        width: 1px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        visibility: hidden;
        color: #c2c2c2;
        &::before,
        &::after {
            visibility: visible;
            position: absolute;
            left: 50%;
        }
        &::after {
            // font-family: "molla";
            content: attr(data-icon);
            top: 20px;
            transform: translateX(-50%);
            font-size: 21px;
        }
        &::before {
            content: attr(data-info);
            top: -45px;
            width: 70px;
            transform: translateX(-50%);
            font-size: 12px;
            @media only screen and (max-width: 450px) {
                top: -30px;
                transform: rotate(-45deg);
                width: 0px;
            }
            // text-indent: -10px;
        }
    }
}

.txn-container {
    // @media only screen and (min-width: 992px) and (max-width: 1080px) {
    //     width: 69vw;
    // }
    // @media only screen and (min-width: 576px) and (max-width: 991px) {
    //     width: 65vw;
    // }
    // @media only screen and (min-width: 100px) and (max-width: 575px) {
    //     width: 95vw;
    // }
    .txns {
        overflow-x: scroll;
        table {
            // width: 100%;
            thead {
                tr {
                    th {
                        text-align: center;
                        color: #c2c2c2;
                        border-bottom: solid 1px #c2c2c2;
                        border-left: solid 1px #c2c2c2;
                    }
                    .first {
                        border-left: none;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        text-align: center;
                        padding-left: 10px;
                        padding-right: 10px;
                        color: #919191;
                        border-bottom: solid 1px #333333;
                        border-left: solid 1px #333333;
                    }
                    .no-align {
                        text-align: start;
                    }
                    .first {
                        border-left: none;
                    }
                }
            }
        }
    }
}

.shareboar {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .input-group {
        width: 80%;
        div {
            button {
                span {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
}

.profile-pic {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    img {
        border-radius: 15px;
    }
    .upload-btn {
        display: none;
        position: absolute;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.582);
        border-radius: 15px;
        align-items: center;
        div {
            cursor: pointer;
            background-color: #fff;
            border-radius: 100%;
            width: 55px;
            height: 55px;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12);
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 24px;
                height: 24px;
            }
        }
        .upload:hover {
            color: white;
            background-color: #c96;
        }
    }
    .btns {
        position: absolute;
        display: flex;
        justify-content: center;
        cursor: pointer;
        bottom: 15px;
        div {
            background-color: #fff;
            border-radius: 100%;
            width: 35px;
            height: 35px;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12);
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 18px;
                height: 18px;
            }
        }
        .edit:hover {
            color: #c96;
        }
        .delete:hover {
            color: #c96;
        }
    }
}

.cust-links {
    display: flex;
    justify-content: space-between;
    .left {
        a {
            color: #c96;
            &:hover {
                color: #9c754d;
            }
            i {
                font-size: 20px;
                position: relative;
            }
            span {
                font-size: 14px;
            }
            @media only screen and (max-width: 1000px) {
                span {
                    display: none;
                }
                i {
                    font-size: 30px;
                }
            }
            @media only screen and (max-width: 650px) {
                i {
                    font-size: 22px;
                }
            }
        }
    }
    .right {
        button {
            svg {
                width: 16px;
                height: 16px;
            }
            color: rgb(230, 230, 230);
            transition: 0.3s;
            &:hover {
                color: #c96;
            }
        }
        @media only screen and (max-width: 500px) {
            button {
                span {
                    display: none;
                }
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.cust-pro {
    @media screen and (max-width: 575px) {
        width: 180px;
        height: 180px;
        overflow: hidden;
        border-radius: 100%;
    }
}

.accordians-cust {
    @media only screen and (min-width: 549px) and (max-width: 750px) {
        font-size: 12px !important;
    }
    @media only screen and (min-width: 449px) and (max-width: 550px) {
        font-size: 10px !important;
    }
    @media only screen and (max-width: 448px) {
        font-size: 9px;
    }
}
